
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PessoaService } from "@/core/services/cadastros";
import {EmpreendimentoService} from "@/core/services/cadastros";
import { SituacaoReceitaParcelaService, TipoReceitaService } from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgBoleto extends Vue {
  filter: {
      empreendimentoIds: any; 
      numeroContrato: string; 
      clienteId: number; 
      empresaIds: any;
      dataContratoInicial:string; 
      dataContratoFinal:string; 
    } = { 
      empreendimentoIds: 0,
      numeroContrato: "",
      clienteId: 0,
      empresaIds: 0,
      dataContratoInicial:"",
      dataContratoFinal: "",
    };

  valid = true;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  pessoas:any = [];
  empreendimentos:any = [];
  empresas = [];


  get selecionarTodosEmpreendimentos(){
    return this.filter.empreendimentoIds.length === this.empreendimentos.length
  }

  get SelecionarAlgunsEmpreendimentos(){
    return this.filter.empreendimentoIds.length > 0 && !this.selecionarTodosEmpreendimentos
  }

  get iconEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
    return 'Selecionar todos'
  }

  SelecionarEmpreendimento() {
    this.$nextTick(() => {
        if (this.selecionarTodosEmpreendimentos) {
        this.filter.empreendimentoIds = []
        } else {
          this.filter.empreendimentoIds = this.empreendimentos.slice().map((x:any)=>x.id)
        }
    })
  }

  SelecionarEmpresa(){
    this.$nextTick(() => {
      if (this.selecionarTodosEmpresas) {
        this.filter.empresaIds = []
      } else {
        this.filter.empresaIds = this.empresas.slice().map((x:any)=>x.id)
      }
    })
  }

  get selecionarTodosEmpresas(){
    return this.filter.empresaIds.length === this.empresas.length
  }

  get SelecionarAlgunsEmpresas(){
    return this.filter.empresaIds.length > 0 && !this.selecionarTodosEmpresas
  }

  get iconEmpresa(){
    if (this.selecionarTodosEmpresas) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpresa(){
    if (this.selecionarTodosEmpresas) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
    return 'Selecionar todos'
  } 

  mounted() {
    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(
        (res) => {
            this.empresas = res.data.items
        }
    );

    const pessoaService = new PessoaService();
    pessoaService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
        (res) => {
            this.pessoas = res.data.items;
        }
    );  

    const empreendimentoService = new EmpreendimentoService();
    empreendimentoService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
    (res) => {
      this.empreendimentos = res.data.items;
    });
  } 
  
  Visualizar() {
    if((!this.filter.dataContratoInicial || !this.filter.dataContratoInicial)){
        this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
        return true;
    }    
    let routeLink = this.$router.resolve({ 
      
    name: "RelVendas",
      query: {
        empreendimentoIds: this.filter.empreendimentoIds ? this.filter.empreendimentoIds.toString() : "",
        clienteId: this.filter.clienteId ? this.filter.clienteId.toString() : "",
        dataContratoInicial: this.filter.dataContratoInicial ? this.filter.dataContratoInicial.toString() : "",
        dataContratoFinal: this.filter.dataContratoFinal ? this.filter.dataContratoFinal.toString() : "",
        empresaIds: this.filter.empresaIds ? this.filter.empresaIds.toString() : "",
        numeroContrato: this.filter.numeroContrato ? this.filter.numeroContrato.toString() : "",
       },
    });
    window.open(routeLink.href, "_blank");
  }
}
